@mixin highlightedHeanding($pHighlightedHeanding){
	border-bottom: 0.2rem solid $pHighlightedHeanding;
}

h1,h2,h3,h4,h5,h6{
	margin: 10px;

	&.highlighted{
		@include highlightedHeanding(darken($color-gray, 15%));
		padding-bottom: 10px;

		&.error{
			@include highlightedHeanding($color-error);
		}

		&.success{
			@include highlightedHeanding($color-success);
		}

		&.info{
			@include highlightedHeanding($color-info);
		}
	}
}

h1{
	font-size: 3.5rem;
}

h2{
	font-size: 3.0rem;
}

h3{
	font-size: 2.5rem;
}

h4{
	font-size: 2.0rem;
}

h5{
	font-size: 1.5rem;
}

h6{
	font-size: 1.0rem;
}