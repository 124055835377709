$bg-color-alert: #f2f2f2;
$color_error-alert : lighten($color_error, 45%);
$color_success-alert : lighten($color_success, 45%);
$color-info-alert : lighten($color-info, 45%);

@function borderColorAlert($pBorderColorAlert){
	@return desaturate(darken($pBorderColorAlert,20%), 20%)
}

@function textColorAlert($pTextColorAlert){
	@return desaturate(darken($pTextColorAlert,40%), 20%)
}

@mixin colorAlert($pAlertColorAlert){
	background-color: $pAlertColorAlert;
	border: 0.1rem solid borderColorAlert($pAlertColorAlert);
	color: textColorAlert($pAlertColorAlert);
}

.alert{
	background-color: $bg-color-alert;
	border: 0.1rem solid borderColorAlert($bg-color-alert);
	@include border-radius;
	color: darken( $bg-color-alert, 40%);
	text-align:justify;
	line-height: 1rem;
	padding: 1rem;
	margin: $margin;

	&.info{
		@include colorAlert($color-info-alert);
	}

	&.error{
		@include colorAlert($color_error-alert);
	}

	&.success{
		@include colorAlert($color_success-alert);
	}

	&.white{
		@include colorAlert(#fff);
		background-color: transparent;
	}

	&.clear{
		background-color: transparent;
		border: none;
		color: textColor(#fff);
	}
}