.float-left{
	float: left;
}

.float-right{
	float: right;
}

.clearfix{
	clear: both;
}